import CommonTable from "components/Tables/CommonTable";
import { Col, Image, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import classNames from "classnames";
import { getNewAccountsRequestApi } from "apis/accounts-request";
import { updateAuctionActionApi } from "apis/accounts-request";
import { NotificationManager } from "react-notifications";
import CustomModal from "components/CustomModal/CustomModal";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { renderStatusBackgroundColor } from "utils/common-functions";
import { capitalizeString } from "utils/common-functions";
import { useDispatch } from "react-redux";
import { _toggleNewAccountRequestBadage } from "actions/generalActions/generalAction";

const NewAccountsRequestImportantAlert = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [
    originalAccountRequestListApiRes,
    setOriginalAccountRequestListApiRes,
  ] = useState(null);
  const [accountsRequestListData, setAccountsRequestListData] = useState(null);
  const [accountRequestRes, setAccountRequestRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getNewAccountsRequestApi(page, pageSize);

      if (response.status === 200 && response.data?.data) {
        setAccountsRequestListData(response.data.data);
        setAccountRequestRes(response?.data);
        setOriginalAccountRequestListApiRes(response?.data?.data);
        dispatch(_toggleNewAccountRequestBadage(response?.data?.data?.length));
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  const onDetailsClick = (data) => {
    setRowData(data);
    setVisibleDetailsModal(true);
  };

  const onUpdateAuctionAction = async (id, action) => {
    try {
      const payload = { userId: id, action };
      const response = await updateAuctionActionApi(payload);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        fetchDataApi();
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const columnsData = [
    {
      title: "Notification",
      key: "accountStatus",
      dataIndex: "accountStatus",
      width: "20%",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },

    {
      title: "Details",
      key: "key",
      width: "60%",
      dataIndex: "key",
      render: (text, row) => {
        return (
          <div>
            <p>
              Attention needed: New account request received from
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: `Name: ${row?.name}<br />
            Email: ${row?.email}<br />
            Phone Number: ${row?.phoneNo}`,
                }}
              />
            </p>
          </div>
        );
      },
    },
    {
      title: "Bussiness License",
      key: "license",
      // dataIndex: "license",
      width: "20%",
      render: (text, record) => {
        return (
          <a href={record?.photo} target="_blank">
            View
          </a>
        );
      },
    },
    {
      title: "Photo",
      key: "photo",
      // dataIndex: "photo",
      width: "20%",
      render: (text, record) => {
        return (
          <a href={record?.photo} target="_blank">
            View
          </a>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      width: "20%",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div>
              <Tooltip placement="top" title={"Approve"}>
                <CheckOutlined
                  onClick={() => onUpdateAuctionAction(row?.id, "approved")}
                  style={{
                    color: renderStatusBackgroundColor("approved"),
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title={"Decline"}>
                <CloseOutlined
                  onClick={() => onUpdateAuctionAction(row?.id, "blocked")}
                  style={{
                    color: renderStatusBackgroundColor("Decline"),
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalAccountRequestListApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setAccountsRequestListData(filteredData);
    } else if (e.target.value === "") {
      setAccountsRequestListData(originalAccountRequestListApiRes);
    } else {
      setAccountsRequestListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          className={style.customerTable}
          data={accountsRequestListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={accountRequestRes?.total}
          columns={columnsData}
          pageSize={pageSize}
        />

        <CustomModal
          visibility={visibleDetailsModal}
          handleCancel={() => setVisibleDetailsModal(false)}
          handleOk={() => null}
          title={"Details"}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Row className={style.modalHeading}>
              <Col span={12}>
                <p> Name </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.name)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Email </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.email)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Phone Number </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.phoneNo)} </p>{" "}
              </Col>
              <Col className={style.docs} span={12}>
                <p> Docs </p>
              </Col>
              <Col span={12}>
                <div>
                  <img src={rowData?.license} alt="" />
                  <img src={rowData?.photo} alt="" />
                </div>
              </Col>
              <Col span={12}>
                <p> Country </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull("UAE")} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Business Name </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.email)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Business Address </p>
              </Col>
              <Col span={12}>
                {" "}
                <p>
                  {" "}
                  {renderItemDataOrEmptyNull(rowData?.businessAddress)}{" "}
                </p>{" "}
              </Col>
            </Row>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default NewAccountsRequestImportantAlert;
